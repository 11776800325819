import React from 'react';
import './NewTable.css';

import usersIcon from '../assets/users.svg';
import memoryIcon from '../assets/memory.svg';
import cpuIcon from '../assets/cpu.svg';
import tsIcon from '../assets/ts.svg';

const NewTable = ({data}) => {
  return (
    <div className='newTable-container'>
        {Object.keys(data).map(i => <div key={data[i].id} className={`newTable-item-container ${data[i].status}`}>
            <div className='newTable-domain'><strong>{data[i].name}</strong></div>
            <div className='newTable-users-cpu'>
                {data[i].type === 'back' ? <><div className='newTable-flex-container'>
                    <div>
                        <img src={usersIcon} alt='users icon' width={36} height={36} />
                    </div>
                    <div>
                        <div className='newTable-text'>{![undefined, null].includes(data[i].guests) ? 'Total Users / Guests' : 'Total Users'}</div>
                        <div className='newTable-stats'>{![undefined, null].includes(data[i].guests) ? `${data[i].users} / ${data[i].guests}` : data[i].users}</div>
                    </div>
                    
                </div>
                <div className='newTable-flex-container'>
                    <div>
                        <img src={cpuIcon} alt='cpu icon' width={36} height={36} />
                    </div>
                    <div>
                        <div className='newTable-text'>CPU Usage</div>
                        <div className='newTable-stats'>{data[i].cpu}</div>
                    </div>
                </div></> : <div className='placeholderText-div'>Web server</div>}
            </div>
            <div className='newTable-memory-ts'>
                {data[i].type === 'back' && <div className='newTable-flex-container'>
                    <div>
                        <img src={memoryIcon} alt='memory icon' width={36} height={36} />
                    </div>
                    <div>
                        <div className='newTable-text'>Memory Usage</div>
                        <div className='newTable-stats'>{data[i].memory}</div>
                    </div>
                </div>}
                <div className='newTable-flex-container'>
                    <div>
                        <img src={tsIcon} alt='ts icon' width={36} height={36} />
                    </div>
                    <div>
                        <div className='newTable-text'>Last Checked</div>
                        <div className='newTable-stats'>{data[i].ts}</div>
                    </div>
                </div>
            </div>
            <div className={`newTable-status ${data[i].status}-status`}>
                <div></div>
                <p>{data[i].status}</p>
            </div>
        </div>)}
    </div>
  )
}

export default NewTable