import React, {useState, useEffect, useCallback, useRef} from 'react'

import Table from '../components/Table';
import Chart from '../components/Chart';

import NewTable from '../components/NewTable';

import '../style/general.css';

const Dashboard = () => {
  // STATE
  const interval = useRef(null);
  const usersInterval = useRef(null);

  const [data, setData] = useState({
    dashboard: {
      id: 'dashboard',
      status: 'OFFLINE',
      name: 'Dashboard - FRONTEND',
      domain: 'dashboard.curoflow.se',
      users: 'N/A',
      cpu: 'N/A',
      memory: 'N/A',
      ts: `Checking...`,
      type: 'front'
    },
    dashboardAPI: {
      id: 'dashboardApi',
      status: 'OFFLINE',
      name: 'Dashboard - BACKEND',
      domain: 'dashboardapi.curoflow.se',
      users: 'N/A',
      cpu: 'N/A',
      memory: 'N/A',
      ts: `Checking...`,
      type: 'back'
    },
  });

  const [dashboardUsers, setDashboardUsers] = useState([]);

  // FETCH FUNCTIONS
  const fetchDashboard = useCallback(() => {
    fetch('https://dashboard.curoflow.se/')
    .then(res => {
      if (res && res.status >= 200 && res.status < 300) {
        setData(prev => ({
          ...prev,
          dashboard: {
            ...prev.dashboard,
            status: 'ONLINE',
            ts: `${new Date().toLocaleTimeString('sv-SV')}`
          }
        }))
      } else {
        setData(prev => ({
          ...prev,
          dashboard: {
            ...prev.dashboard,
            status: 'OFFLINE',
            ts: `${new Date().toLocaleTimeString('sv-SV')}`
          }
        }));
      }
    })
    .catch(err => {
      setData(prev => ({
        ...prev,
        dashboard: {
          ...prev.dashboard,
          status: 'OFFLINE',
          ts: `${new Date().toLocaleTimeString('sv-SV')}`
        }
      }))
    });
  }, []);

  const fetchDashboardApi = useCallback(() => {
    fetch('https://dashboardapi.curoflow.se/system-monitoring/stats')
    .then(res => {
      if (res && res.ok) {
        return res.json();
      }
    })
    .then(data => {
      if (!data) {
        setData(prev => ({
          ...prev,
          dashboardAPI: {
            ...prev.dashboardAPI,
            status: 'OFFLINE',
            users: 'N/A',
            cpu: 'N/A',
            memory: 'N/A',
            ts: `${new Date().toLocaleTimeString('sv-SV')}`
          }
        }));
        return;
      }
      setData(prev => ({
        ...prev,
        dashboardAPI: {
          ...prev.dashboardAPI,
          status: 'ONLINE',
          users: data?.totalNumberOfUsers || 'N/A',
          cpu: data?.cpuPercent?.toFixed(2) + ' %' || 'N/A',
          memory: data?.memoryPercent?.toFixed(2) + ' %' || 'N/A',
          ts: `${new Date().toLocaleTimeString('sv-SV')}`
        }
      }));
    })
    .catch(err => {
      setData(prev => ({
        ...prev,
        dashboardAPI: {
          ...prev.dashboardAPI,
          status: 'OFFLINE',
          users: 'N/A',
          cpu: 'N/A',
          memory: 'N/A',
          ts: `${new Date().toLocaleTimeString('sv-SV')}`
        }
      }));
    });
  }, []);

  const getDashboardUsers = useCallback(() => {
    fetch('https://dashboardapi.curoflow.se/system-monitoring/users')
    .then(res => res.json())
    .then(data => {
      if (data) {
        setDashboardUsers(data);
      }
    })
    .catch(err => console.log(err));
  }, []);

  // EFFECTS AND INTERVAL
  useEffect(() => {
    if (interval?.current) {
      clearInterval(interval.current);
      interval.current = null;
    }
    if (usersInterval?.current) {
      clearInterval(usersInterval.current);
      usersInterval.current = null;
    }

    fetchDashboard();
    fetchDashboardApi();

    // Users data - fire only once - no interval!
    getDashboardUsers();

    interval.current = setInterval(() => {
      fetchDashboard();
      fetchDashboardApi();
    }, 10 * 1000);

    usersInterval.current = setInterval(() => {
      getDashboardUsers();
    }, 10 * 60 * 1000);

    return () => {
      clearInterval(interval.current);
      clearInterval(usersInterval.current);
    }
  }, []);

  

  // RENDER
  return (<div className='container'>
      <h1>Curoflow System Monitoring</h1>
      <h2>Dashboard Platform</h2>
      <div className='dashboard-container'>
        <NewTable data={data} />
        <Chart className='dashboard-chart' data={dashboardUsers} title='New dashboard users' />
      </div>
    </div>
  )
}

export default Dashboard