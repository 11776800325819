import {Routes, Route, useRoutes} from 'react-router-dom';

import All from './pages/All';
import Dashboard from './pages/Dashboard';
import Video from './pages/Video';
import Login from './pages/Login';
import useStore from './hooks/useStore';
import { useEffect } from 'react';

import {checkLogin} from './bcrypt/check';
import { useSearchParams } from 'react-router-dom';

function App() {
  const {user, setUser} = useStore();
  const [params] = useSearchParams();

  useEffect(() => {
    if (typeof window !== 'undefined' && localStorage && localStorage.getItem('username') && localStorage.getItem('password')) {
      const user = checkLogin(localStorage.getItem('username'), localStorage.getItem('password'));
      if (user) {
        setUser('curoflow');
      } else {
        localStorage.removeItem('username');
        localStorage.removeItem('password');
      }
    } else if (params.get('username') && params.get('password')) {
      const user = checkLogin(params.get('username'), params.get('password'));
      if (user) {
        setUser('curoflow');
        if (typeof window !== 'undefined' && localStorage) {
          localStorage.setItem('username', params.get('username'));
          localStorage.setItem('password', params.get('password'));
        }
      }
    }
  }, [])

  console.log(params.get('test'));

  if (!user) {
    return <Login />
  }

  return (
    <Routes>
      <Route path='/' element={<All />} />
      <Route path='/dashboard' element={<Dashboard />} />
      <Route path='/video' element={<Video />} />

      <Route path='*' element={<All />} />
    </Routes>
  );
}

export default App;
