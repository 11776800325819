const bcrypt = require('bcryptjs');

const USERNAME = '$2a$10$xHbqB5RP4JexNTvtdGWe4uJVwebVFt2b2OxyYwdHQkqXynfjOFlnK';
const PW = '$2a$10$xHbqB5RP4JexNTvtdGWe4uhv9OwkMY.7VvL955gQ5D4B/aU.ZwBLi';

const checkLogin = (username, pw) => {
    let usernameIsValid = bcrypt.compareSync(username, USERNAME)
    let passwordIsValid = bcrypt.compareSync(pw, PW);

    if (usernameIsValid && passwordIsValid) {
        return 'curoflow';
    } else {
        return false;
    }
}

module.exports = {checkLogin}