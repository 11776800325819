import React, {useEffect, useState} from 'react';
import useStore from '../hooks/useStore';

import {checkLogin} from '../bcrypt/check';

import '../components/Login.css';

const Login = () => {
    const [username, setUsername] = useState('');
    const [pw, setPw] = useState('');
    const [err, setErr] = useState(null);

    const {user, setUser} = useStore();

    const submit = (e) => {
        e.preventDefault();
        const user = checkLogin(username, pw);
        if (user) {
            setUser(user);
            if (typeof window !== 'undefined' && localStorage) {
                localStorage.setItem('username', username);
                localStorage.setItem('password', pw);
            }
        } else {
            setErr('Wrong username and/or password!');
        }
    }

    useEffect(() => {
        if (err) {
            setErr(null);
        }
    }, [username, pw])

  return (
    <section className='login-container'>
        <form onSubmit={submit}>
            <h2>Log in:</h2>
            <div>
                <label htmlFor='username'>Username:</label>
                <input id='username' type='text' value={username} placeholder='Username' onChange={(e) => setUsername(e?.target?.value)} />
            </div>
            <div>
                <label htmlFor='password'>Password:</label>
                <input id='password' type='password' value={pw} placeholder='Password' onChange={(e) => setPw(e?.target?.value)} />
            </div>

            {err && <div className='error'>
                <p>{err}</p>    
            </div>}

            <div className='login-form-button-div'>
                <button type='submit'>Log In</button>
            </div>
        </form>
    </section>
  )
}

export default Login