import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Filler,
  Legend,
} from 'chart.js';
import { Line } from 'react-chartjs-2';

import './Chart.css';


ChartJS.register(
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Filler,
  Legend
);

export default function Chart({data, title, className}) {
    const DATA = {
        labels: data.map(obj => obj.period),
        datasets: [
            {
                fill: true,
                label: 'Number of new users: ',
                data: data.map(obj => obj.count),
                borderColor: 'rgba(86, 138, 200, 0.5)',
                backgroundColor: 'rgba(230, 237, 245, 1)',
            }
        ]
    }
    const options = {
      responsive: true,
      plugins: {
        legend: {
          display: false,
          position: 'bottom',
        },
        title: {
          display: false,
          text: title,
        },
      },
    };
  return <div className={`chartContainer ${className}`}>
      <h3>{title}</h3>
      <Line options={options} data={DATA} />
    </div>;
}
