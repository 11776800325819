import {createContext, useState} from 'react';

const Context = createContext({});

export const ContextProvider = ({children}) => {
    const [user, setUser] = useState(null);

    let value = {
        user,
        setUser,
    }
    return (
        <Context.Provider value={value}>
            {children}
        </Context.Provider>
    )
}

export default Context;