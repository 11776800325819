import React, {useState, useEffect, useCallback, useRef} from 'react'

import Table from '../components/Table';
import Chart from '../components/Chart';

import NewTable from '../components/NewTable';

import '../style/general.css';

const Video = () => {
  // STATE
  const interval = useRef(null);
  const usersInterval = useRef(null);

  const [data, setData] = useState({
    video: {
      id: 'video',
      status: 'OFFLINE',
      name: 'Video - FRONTEND',
      domain: 'video.curoflow.se',
      users: 'N/A',
      cpu: 'N/A',
      memory: 'N/A',
      ts: `Checking...`,
      type: 'front'
    },
    videoApi: {
      id: 'videoApi',
      status: 'OFFLINE',
      name: 'Video - BACKEND',
      domain: 'video-api.curoflow.se',
      users: 'N/A',
      guests: 'N/A',
      cpu: 'N/A',
      memory: 'N/A',
      ts: `Checking...`,
      type: 'back'
    },
  });

  const [videoUsers, setVideoUsers] = useState([]);

  // FETCH FUNCTIONS
  const fetchVideo = useCallback(() => {
    fetch('https://video.curoflow.se/')
    .then(res => {
      if (res && res.status >= 200 && res.status < 300) {
        setData(prev => ({
          ...prev,
          video: {
            ...prev.video,
            status: 'ONLINE',
            ts: `${new Date().toLocaleTimeString('sv-SV')}`
          }
        }))
      } else {
        setData(prev => ({
          ...prev,
          video: {
            ...prev.video,
            status: 'OFFLINE',
            ts: `${new Date().toLocaleTimeString('sv-SV')}`
          }
        }));
      }
    })
    .catch(err => {
      setData(prev => ({
        ...prev,
        video: {
          ...prev.video,
          status: 'OFFLINE',
          ts: `${new Date().toLocaleTimeString('sv-SV')}`
        }
      }))
    });
  }, []);

  const fetchVideoApi = useCallback(() => {
    fetch('https://video-api.curoflow.se/system-monitoring/stats')
    .then(res => {
      if (res && res.ok) {
        return res.json();
      }
    })
    .then(data => {
      if (!data) {
        setData(prev => ({
          ...prev,
          videoApi: {
            ...prev.videoApi,
            status: 'OFFLINE',
            users: 'N/A',
            cpu: 'N/A',
            memory: 'N/A',
            ts: `${new Date().toLocaleTimeString('sv-SV')}`
          }
        }));
        return;
      }
      setData(prev => ({
        ...prev,
        videoApi: {
          ...prev.videoApi,
          status: 'ONLINE',
          users: data?.totalNumberOfUsers || 'N/A',
          guests: data?.totalNumberOfGuest || 0,
          cpu: data?.cpuPercent?.toFixed(2) + ' %' || 'N/A',
          memory: data?.memoryPercent?.toFixed(2) + ' %' || 'N/A',
          ts: `${new Date().toLocaleTimeString('sv-SV')}`
        }
      }));
    })
    .catch(err => {
      setData(prev => ({
        ...prev,
        videoApi: {
          ...prev.videoApi,
          status: 'OFFLINE',
          users: 'N/A',
          cpu: 'N/A',
          memory: 'N/A',
          ts: `${new Date().toLocaleTimeString('sv-SV')}`
        }
      }));
    });
  }, []);

  const getVideoUsers = useCallback(() => {
    fetch('https://video-api.curoflow.se/system-monitoring/users')
    .then(res => res.json())
    .then(data => {
      if (data) {
        setVideoUsers(data);
      }
    })
    .catch(err => console.log(err));
  }, []);

  // EFFECTS AND INTERVAL
  useEffect(() => {
    if (interval?.current) {
      clearInterval(interval.current);
      interval.current = null;
    }
    if (usersInterval.current) {
      clearInterval(usersInterval.current);
      usersInterval.current = null;
    }

    fetchVideo();
    fetchVideoApi();

    // Users data - fire only once - no interval!
    getVideoUsers();

    interval.current = setInterval(() => {
      fetchVideo();
      fetchVideoApi();
    }, 10 * 1000);

    usersInterval.current = setInterval(() => {
      getVideoUsers();
    }, 10 * 60 * 1000);

    return () => {
      clearInterval(interval.current);
      clearInterval(usersInterval.current);
    }
  }, []);

  

  // RENDER
  return (<div className='container'>
      <h1>Curoflow System Monitoring</h1>
      <h2>Video Platform</h2>
      <div className='video-container'>
        <NewTable data={data} />
        <Chart className='video-chart' data={videoUsers} title='New video users' />
      </div>
    </div>
  )
}

export default Video